
import { defer } from 'lodash'

import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import InternalPage from 'navigation/pages/InternalPage'
import { StoreEmitter } from 'helpers/state'
import MainPage from 'navigation/pages/MainPage'
import scroll from 'core/scroll'
import store from 'store'
import sizeStore from 'store/sizeStore'
import Component from 'navigation/component/Component'

class InternalRouter extends MainPage { 
 static pageName = 'InternalRouter'; 
  getModuleMap () {
    return {
      footer: super.getModuleMap().footer
    }
  }

  getDefaultPageClass () {
    return InternalPage
  }

  getPageSelector () {
    return '.internal-page'
  }

  constructor () {
    super(...arguments)

    this.bindRefs()
  }

  bindModules (previousPage) {
    if (this.modules) return

    Component.prototype.bindModules.call(this)
    this.modules = {
      ...this.modules,
      content: this.initContentRouter(previousPage)
    }

    this.modulesArray.push(this.modules.content)
    defer(() => this.bindEvents(true))
  }

  initContentRouter (previousPage) {
    return new VirtualPageManager(new StoreEmitter(), this.refs.internalRouter, this.getPageSelector(),
      this.getDefaultPageClass(),
      { pageManager: this.pageManager, parent: this, previousMainPage: previousPage }
    )
  }

  shouldRouteInternally () {
    return true
  }

  scrollToTop (cb) {
    const minHeight = sizeStore.notificationHeight.get()
    if (document.scrollingElement.scrollTop > minHeight) scroll.scrollTo(minHeight).then(cb)
    else cb()
  }

  internalRouting (pathName, xhr, requestOptions = {}) {
    store.loading.set(0)
    return this.scrollToTop(() => this.modules.content.forceRouteUpdate(pathName, xhr, requestOptions))
  }
}

export default InternalRouter
