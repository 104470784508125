
import { throttle } from 'lodash'

import detect from './detect'

let cache = {}, history = {}

let root = false
let ignoreScrollResize = true

const dimension = () => {
  if (cache.dimension) return cache.dimension

  const dimension = {
    width: !root ? window.innerWidth : root.offsetWidth,
    innerWidth: document.body.offsetWidth,
    height: window.innerHeight
    // height: !root ? window.innerHeight : root.offsetHeight
  }

  dimension.innerHeight = dimension.height
  dimension.scrollWidth = dimension.width - dimension.innerWidth

  // Ignore ios resize on scroll (wheen bottom bar disappear)
  if (ignoreScrollResize &&
    detect.iphone &&
    history.dimension &&
    history.dimension.width === dimension.width)
    dimension.innerHeight = history.dimension.innerHeight

  dimension.ratio = dimension.width / dimension.height

  return (cache.dimension = dimension)
}

const width = () => dimension().width
const height = () => dimension().height
const ratio = () => dimension().ratio
const scrollWidth = () => dimension().scrollWidth
const outerWidth = () => dimension().outerWidth
const testMQ = (mq) => () => cache[mq] || (cache[mq] = window.matchMedia(mq).matches)

const clear = () => {
  history = cache
  cache = {}
}

let listeners = []
const resize = (e) => {
  _resize(e)
  // event && resizeBigDebounce()
}

const _resize = throttle(() => {
  clear()
  listeners.forEach((listener) => listener && listener.resize && listener.resize())
}, 50)

// const resizeBigDebounce = debounce(() => {
//   if (resizing) document.documentElement.classList.remove('resizing')
//   resizing = false
// }, 500, {
//   leading: false,
//   trailing: true
// })

window.addEventListener('resize', resize)
window.addEventListener('orientationchange', resize)

const add = (listener) => listeners.push(listener)
const remove = (listener) => (listeners = listeners.filter(item => item !== listener))

const setRoot = r => {
  root = r
  clear()
}
const setIgnoreScrollResize = i => (ignoreScrollResize = !!i)

export default {
  dimension,
  scrollWidth,
  outerWidth,
  ratio,
  width,
  height,
  clear,
  add,
  remove,
  setRoot,
  setIgnoreScrollResize,
  resize,
  testMQ
}
