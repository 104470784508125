/* global google */
import { debounce, find } from 'lodash-es'

import Component from 'navigation/component/Component'
import store from 'store/store'
import promise from 'helpers/promise'
import config from 'core/config'

import validators from '../form/components/validators'

let _gmap

const gmap = () => {
  if (_gmap) return _gmap.promise

  _gmap = promise.defer()

  const url = `//maps.googleapis.com/maps/api/js?key=${config.gmapKey}&libraries=places&language=${store.lang.get()}&callback=onGmapReady`

  const script = document.createElement('script')
  script.setAttribute('src', url)
  document.body.appendChild(script)

  window.onGmapReady = () => {
    _gmap.ready = true
    _gmap.resolve(new google.maps.places.AutocompleteSessionToken())
    window.onGmapReady = null
  }

  return _gmap.promise
}

const getField = (address, addressKey) => {
  return find(address, (a) => ~a.types.indexOf(addressKey))
}

const getFieldValue = (address, addressKey, format) => {
  if (!format) format = (v) => v && v.long_name
  const result = format(getField(address, addressKey))
  if (result && result !== '[no name]') return result
}

class AddressField extends Component {
  constructor (el, refs) {
    super(el)
    this.parseForm()
    gmap().then((token) => this.initGmap(token))
  }

  initGmap = (token) => {
    if (this._flushed) return
    this.token = token
    this.autocompleteService = new google.maps.places.AutocompleteService()
    this.placeService = new google.maps.places.PlacesService(document.createElement('div'))
  }

  parseForm () {
    this.streetInput = this.el
    this.streetField = this.streetInput.parentNode.parentNode
    this.streetField.classList.add('with-suggestions')

    this.suggestionsDiv = document.createElement('div')
    this.suggestionsDiv.className = 'field__suggestions'
    this.streetField.appendChild(this.suggestionsDiv)
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.streetInput[method]('input', this.onInput)
  }

  onInput = (event) => {
    const search = this.streetInput.value
    if (search) this.streetField.classList.add('loading')

    if (this.autocompleteService)
      this.debouncedSearch(search)
  }

  debouncedSearch = debounce((search) => {
    if (!search) return this.updateSuggestions()

    const request = {
      input: search,
      types: ['address'],
      language: store.lang.get(),
      sessionToken: this.token
    }

    const lastSearch = Date.now()
    this.lastSearch = lastSearch

    this.autocompleteService.getPlacePredictions(request, (results, status) => {
      if (this.lastSearch === lastSearch) this.streetField.classList.remove('loading')
      if (status !== google.maps.places.PlacesServiceStatus.OK) return
      this.updateSuggestions(results.map((result) => ({
        label: result.structured_formatting.main_text,
        description: result.structured_formatting.secondary_text,
        value: result.structured_formatting.main_text,
        placeId: result.place_id
      })))
    })
  }, 200)

  clearSuggestions () {
    this.suggestions && this.suggestions.forEach((div) => {
      div.removeEventListener('click', this.onSuggestionClick)
    })
    this.suggestions = null
  }

  updateSuggestions (suggestions) {
    this.clearSuggestions()

    this.suggestionsDiv.classList.toggle('opened', !!suggestions)

    if (!suggestions) return

    this.suggestionsDiv.innerHTML = ''
    this.suggestions = suggestions.map((suggestion) => {
      const { label, description } = suggestion
      const div = document.createElement('button')
      div.className = 'field__suggestion'
      div.setAttribute('data-info', JSON.stringify(suggestion))
      div.addEventListener('click', this.onSuggestionClick)
      div.innerHTML = `
        <h5 class="field__suggestion-title">${label}</h5>
        <div class="field__suggestion-description">${description}</div>
      `
      this.suggestionsDiv.appendChild(div)

      return div
    })
  }

  onSuggestionClick = (event) => {
    event.preventDefault()
    const info = JSON.parse(event.currentTarget.getAttribute('data-info'))

    this.placeService.getDetails({
      placeId: info.placeId,
      language: store.lang.get(),
      fields: ['address_component'],
      sessionToken: this.token
    }, (result, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) return
      this.emit('select', this.extractAddress(result.address_components, info))
      this.updateSuggestions()
      // this.fillAddress(this.extractAddress(result.address_components, info))
    })
  }

  // fillAddress = (address) => {
  //   each(address, (value, key) => {
  //     const input = this.el.querySelector(`[name="${key}"]`)
  //     if (!input) return
  //     input.value = value
  //   })

  //   this.updateSuggestions()
  // }

  extractAddress = (addressDetails, addressInfo) => {
    const object = { address: addressInfo.label }

    if (!object.address || !validators.street.isValidSync(object.address)) {
      const street = getFieldValue(addressDetails, 'route')
      const streetNumber = getFieldValue(addressDetails, 'street_number')

      if (street && streetNumber) object.address = `${streetNumber} ${street}`
      else if (street) object.address = street
    }

    object.city = getFieldValue(addressDetails, 'locality')
    if (!object.city) object.city = getFieldValue(addressDetails, 'postal_town')
    if (!object.city) object.city = getFieldValue(addressDetails, 'sublocality')
    if (!object.city) object.city = getFieldValue(addressDetails, 'administrative_area_level_1')

    object.country = getFieldValue(addressDetails, 'country', v => v && v.short_name)
    object.countryName = getFieldValue(addressDetails, 'country', v => v && v.long_name)
    object.postcode = getFieldValue(addressDetails, 'postal_code')
    const postcodeSuffix = getFieldValue(addressDetails, 'postal_code_suffix')
    if (object.postcode && postcodeSuffix) object.postcode += '' + postcodeSuffix

    object.state = getFieldValue(addressDetails, 'administrative_area_level_1', v => v && v.short_name)
    // object.region2 = getFieldValue(addressDetails, 'administrative_area_level_2', v => v && v.short_name)

    if (object.country === 'KR' && object.postcode) delete object.postcode

    object.phone_code = object.country

    Object.keys(object).forEach((key) => !object[key] && delete object[key])

    return object
  }

  flush () {
    this.clearSuggestions()
    super.flush()
  }
}

export default AddressField
