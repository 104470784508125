
import { delay } from 'lodash'

import PanelPage from 'navigation/pages/PanelPage'
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import { StoreEmitter } from 'helpers/state'
import InternalRouter from 'navigation/pages/InternalRouter'
import store from 'store'

import CheckoutBreadcrumb from './partials/CheckoutBreadcrumb'
import CheckoutPopin from './partials/CheckoutPopin'

class Checkout extends InternalRouter { 
 static pageName = 'Checkout'; 
  constructor () {
    super(...arguments)
    this.testStep(this.el)
    this.bindModules()
  }

  getModuleMap = () => ({
    popin: ['.checkout-popin', CheckoutPopin],
    breadcrumb: ['.checkout-breadcrumb', CheckoutBreadcrumb]
  })

  preload () {
    if (!this.modules.summary) return super.preload()
    return Promise.all([
      super.preload(),
      new Promise(resolve => this.modules.summary.once('loaded', resolve))
    ])
  }

  bindModules () {
    if (this.modules) return
    super.bindModules()

    if (this.refs.checkoutSummary.hasAttribute('default-route'))
      this.modules.summary = this.initSummaryRouter()

    this.modulesArray = Object.values(this.modules)
  }

  openPopin (url) {
    this.modules.popin.store.set(url)
  }

  initSummaryRouter () {
    return new VirtualPageManager(new StoreEmitter(), this.refs.checkoutSummary, '.page', PanelPage)
  }

  getPageSelector () {
    return '.checkout__step'
  }

  shouldRouteInternally (el) {
    if (this.step >= 5) return false
    const page = el.querySelector(this.getPageSelector())
    if (!page) return false
    const step = page.getAttribute('data-step')
    if (step) return (+step) < 5
  }

  testStep (el) {
    const page = el.querySelector(this.getPageSelector())
    if (!page) return
    const step = page.getAttribute('data-step')
    if (step) {
      this.step = +step
      store.checkoutStep.set(+step)
    }
  }

  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const { summary } = this.modules
    summary && summary[add ? 'on' : 'off']('virtual', this.refreshContent)
  }

  internalRouting (pathName, xhr, requestOptions = {}) {
    this.testStep(xhr.response)
    super.internalRouting(...arguments)

    const { summary } = this.modules
    const refresh = () => summary && summary.refresh()

    const container = this.refs.checkoutSummary
    if (container.classList.contains('opened')) {
      this.refs.checkoutSummary.classList.remove('opened')
      delay(refresh, 400)
    } else { refresh() }
  }

  refreshContent = () => {
    const { content, summary } = this.modules
    if (content.page.current.pageName() === 'CheckoutPayment' && summary) {
      summary.once('show', () => {
        content.refresh()
      })
    }
  }
}

export default Checkout
