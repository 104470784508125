import QuickAccess from 'components/quick-access/QuickAccess'
import { staggerItems } from 'core/animations'
import config from 'core/config'
import PanelPage from 'navigation/pages/PanelPage'
import store from 'store/store'

class Menu extends PanelPage { 
 static pageName = 'Menu'; 
  constructor (el) {
    super(...arguments)
    this.bindRefs()
  }

  bindEvents () {
    store.alternateLinks.listenAndStart(this.onAlternateLinkUpdate)
  }

  getAnimationTargets () {
    return QuickAccess.prototype.getAnimationTargets.call(this).concat(this.refs.menuLinks)
  }

  show () {
    setTimeout(() => {
      const targets = this.getAnimationTargets()
      staggerItems(targets, { initialDelay: 0 })
    }, 20)

    return super.show(...arguments)
  }

  onAlternateLinkUpdate = (links) => {
    this.refs.menuAlternateLinks.forEach(link => {
      const lang = link.getAttribute('data-lang')
      const href = links[lang]
      if (href) link.setAttribute('href', href)
      else link.setAttribute('href', config.baseurl + '/' + lang)
    })
  }
}

export default Menu
