import { defer } from 'lodash-es'
import anime from 'animejs'

// import store from 'store/store'

import PanelPage from './PanelPage'
import Page from './Page'

export default class SearchPage extends PanelPage { 
 static pageName = 'SearchPage'; 
  constructor (el, options = {}) {
    options.className = 'search-panel__page'
    super(el, options)
  }

  bindEvents (add = true) {
    super.bindEvents()

    // const method = add ? 'addEventListener' : 'removeEventListener'
    // this.refs.closeSearch[method]('click', this.onClose)
  }

  // onClose = () => {
  //   store.search.set(null)
  // }

  /* SHOW */

  show (previousPage) {
    this.resize()
    Page.prototype.show.call(this, previousPage)

    return anime({
      targets: this.el,
      translateY: {
        duration: 700,
        value: ['-100%', '0'],
        easing: 'easeInOutCubic'
      },
      opacity: {
        duration: 400,
        value: !previousPage ? 1 : [0, 1],
        easing: 'linear'
      },
      changeBegin: () => {
        this.invokeShow()
      },
      complete: () => {
        this.el.style.transform = ''
        defer(() => this.resize())
      }
    }).finished
  }

  /* HIDE */

  hide (nextPage) {
    return anime({
      targets: this.el,
      translateY: {
        duration: 700,
        value: ['0%', nextPage ? '50%' : '-100%'],
        easing: 'easeInOutCubic'
      },
      opacity: {
        value: 0,
        easing: 'linear',
        duration: 400
      }
    }).finished
  }
}
