import { defer, pick, pickBy, each, omit } from 'lodash'

import Form from 'components/form/Form'
import InternalPage from 'navigation/pages/InternalPage'
import RadioGroup from 'components/radio-group/RadioGroup'

class CheckoutPayment extends InternalPage { 
 static pageName = 'CheckoutPayment'; 
  getModuleMap () {
    const modules = super.getModuleMap()
    delete modules.radioGroup
    return {
      ...modules,
      creditForm: ['.credit-form', Form],
      radioGroup: ['.form .radio-group', RadioGroup],
      creditRadioGroup: ['.credit-form .radio-group', RadioGroup]
    }
  }

  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const method = add ? 'on' : 'off'
    const method2 = add ? 'addEventListener' : 'removeEventListener'
    this.modules.radioGroup.forEach(r => r[method]('change', this.onMethodChange, this))
    if (this.modules.creditRadioGroup)
      this.modules.creditRadioGroup[method]('change', this.onCreditChange, this)
    this.refs.billingPanel[method2]('transitionend', this.onTransitionEnd)
    this.onMethodChange()

    Array.from(this.el.querySelectorAll('[data-test-cc]')).forEach(b => b[method2]('click', this.onTestCard))
  }

  onTestCard = (event) => {
    event.preventDefault()

    const date = new Date()
    const data = {
      CT: event.currentTarget.getAttribute('data-test-cc'),
      CARDNO: event.currentTarget.getAttribute('data-test-number'),
      ECOM_CARDINFO_EXPDATE_YEAR: date.getFullYear(),
      ECOM_CARDINFO_EXPDATE_MONTH: date.getMonth() + 1,
      CVC: '123',
      CN: 'Test'
    }

    const { form } = this.modules
    each(data, (val, key) => {
      const input = form.el.elements[key]
      switch (input.type) {
        case 'checkbox': input.checked = !!val; break
        default: input.value = val; break
      }
    })

    setTimeout(() => form.quickValid(), 10)
  }

  bindModules () {
    super.bindModules()
    const { form } = this.modules

    const validations = Object.assign({}, form.validations)
    const baseValidation = pick(validations, ['billing', 'mode'])
    const cardValidations = pickBy(validations, (value, key) => key.match(/^(CT|CARDNO|ECOM_CARDINFO_EXPDATE_.+|CVC|CN)$/))
    const addressValidation = omit(validations, [...Object.keys(baseValidation), ...Object.keys(cardValidations)])

    this.validations = {
      default: validations,
      card: cardValidations,
      address: addressValidation,
      base: baseValidation
    }

    form.preventDefault = false
    form.defaultAction = form.action
  }

  onCreditChange (value, name) {
    this.modules.creditForm.submit()
  }

  onMethodChange (value, name, input) {
    defer(() => {
      const data = this.modules.radioGroup.reduce((memo, radio) => {
        memo[radio.name] = radio.value
        return memo
      }, {})

      const schema = { ...this.validations.base }
      const { form } = this.modules

      if (input && name === 'mode') {
        if (input.hasAttribute('data-action')) form.action = input.getAttribute('data-action')
        else form.action = form.defaultAction
        form.el.action = form.action
      }

      if (data.mode === 'card') Object.assign(schema, this.validations.card)
      if (data.address === 'new') Object.assign(schema, this.validations.address)

      this.toggleBillingPanel(!!data.mode)
      form.updateSchema(schema)
      setTimeout(() => form.quickValid(), 10)
    })
  }

  toggleBillingPanel (open) {
    this.resize()
    if (!open) this.refs.billingPanel.classList.remove('opened-complete')

    setTimeout(() => {
      this.refs.billingPanel.classList.toggle('opened', open)
    }, open ? 0 : 50)
  }

  onTransitionEnd = (event) => {
    if (event.target !== event.currentTarget) return
    if (this.refs.billingPanel.classList.contains('opened'))
      this.refs.billingPanel.classList.add('opened-complete')
  }

  resize () {
    super.resize()
  }

  flush () {
    super.flush()
  }
}

export default CheckoutPayment
