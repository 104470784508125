import 'normalize.css/normalize.css'
import 'styles/main.scss'

import resize from 'helpers/resize'

import 'helpers/detect'
import App from './App'

resize.setRoot(document.body)

App.prototype.updateSizes()
window.onload = () => new App(document.body)

document.documentElement.classList.add('app-started')

if (module.hot) module.hot.accept()
