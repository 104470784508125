
import { isArray } from 'lodash'

import Form, { getFormParameters } from 'components/form/Form'
import Component from 'navigation/component/Component'
import store from 'store'
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager'
import mqStore from 'store/mqStore'
import resize from 'helpers/resize'
import sizeStore from 'store/sizeStore'

import ProductInfos from './ProductInfos'
import ProductColors from './ProductColors'

class ProductDetails extends Component {
  getModuleMap () {
    return {
      ...super.getModuleMap(),
      form: ['.form', Form],
      productColors: ['.product-colors', ProductColors],
      productInfos: ['.product-infos', ProductInfos]
    }
  }

  constructor () {
    super(...arguments)
    this.bindRefs(this.el.parentNode)

    this.bindModules()

    this.isOneSize = !this.refs.productSizeButtons

    if (this.isOneSize) {
      setTimeout(() => {
        this.emit('valid')
        this.modules.form.quickValid()
      }, 10)
    }

    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(this.observerResize)
      this.resizeObserver.observe(this.el)
    }
  }

  observerResize = (e) => {
    this.computeAlignement()
  }

  bindModules () {
    super.bindModules()
    this.modules.form.submitCallback = () => { return false }
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'

    if (this.refs.productAddButton) this.refs.productAddButton[method]('click', this.addProduct)
    if (this.refs.productAddMobileButton) this.refs.productAddMobileButton[method]('click', this.addProduct)
    if (this.refs.productFavButton) this.refs.productFavButton[method]('click', this.favProduct)
    if (this.refs.productMobileOverlay) this.refs.productMobileOverlay[method]('click', this.closeSize)
    if (this.refs.productForm) this.refs.productForm[method]('submit', this.onFormSubmit)

    store.popin[add ? 'listen' : 'unlisten'](this.closeSize)

    if (isArray(this.refs.productSizeButtons)) this.refs.productSizeButtons.forEach(button => button[method]('change', this.onSizeSelected))
  }

  /* SIZES */

  // enableButtons (enable) {
  //   this.refs.productButtons.classList.toggle('enabled', enable)
  // }

  onSizeSelected = event => {
    const form = this.refs.productForm
    const formData = new FormData(form)
    const count = +formData.get('article_count')
    const valid = formData.get('article') && (count < 2 || formData.get('article2'))

    if (valid && this.waitSizes) this.submitMobile()
    this.emit('valid')

    // this.enableButtons(valid)
  }

  favProduct = (event = {}) => {
    const action = this.refs.productFavButton.getAttribute('data-action')
    this.prepareSendForm({
      submitter: this.refs.productFavButton,
      action,
      callback: ({ data, xhr }) => {
        const page = xhr.response
        const login = page.querySelector('[data-page="Login"]')

        if (login) {
          const { panel } = store.routers.get()
          panel.inject(action, xhr)
          return
        }

        if (!data.error && !data.message) {
          const { main } = store.routers.get()
          main.inject(action, xhr)
        }
      }
    })
  }

  addProduct = (event = {}) => {
    const form = this.refs.productForm

    this.prepareSendForm({
      submitter: this.refs.productAddButton,
      callback: ({ data, xhr }) => {
        if (!data.error) {
          const { panel } = store.routers.get()
          panel.inject(form.action, xhr, { method: form.method })
        }
      }
    })
  }

  prepareSendForm (data) {
    if (mqStore.tabletPortrait.get() && !this.isOneSize) this.openMobileSize(data)
    else this.sendForm(data)
  }

  sendForm ({ submitter, action = null, callback } = {}) {
    event.preventDefault && event.preventDefault()
    if (this.loading) return

    this.modules.form.quickValid()
      .then((valid) => {
        if (!valid) return
        const form = this.refs.productForm
        const { formData } = getFormParameters({ currentTarget: form, submitter })

        if (formData.get('article2')) {
          if (formData.get('article')) formData.append('articles[]', formData.get('article'))
          formData.append('articles[]', formData.get('article2'))
          formData.delete('article')
          formData.delete('article2')
        }

        this.loading = true

        const xhr = new XMLHttpRequest()
        xhr.withCredentials = true
        xhr.open(form.method, action || form.action, true)
        xhr.responseType = 'document'
        xhr.onload = this.onXhrComplete(callback)
        xhr.send(formData)
      })
  }

  onXhrComplete = (resolve) => (event) => {
    this.loading = false
    const xhr = event.currentTarget
    xhr.onload = null

    const data = extractInfoFromXhr(xhr)
    resolve({ data, xhr })
  }

  openMobileSize = (data) => {
    event && event.preventDefault()
    const form = this.refs.productForm
    form.reset()
    form.classList.add('size-opened')
    this.waitSizes = data
  }

  closeSize = (event) => {
    this.waitSizes = false
    const form = this.refs.productForm
    form.classList.remove('size-opened')
  }

  submitMobile () {
    if (!this.waitSizes) return
    const form = this.refs.productForm
    form.classList.remove('size-opened')
    this.sendForm(this.waitSizes)
    this.waitSizes = false
  }

  computeAlignement () {
    if (mqStore.tabletPortrait.get()) return
    // Compute product details alignement
    const top = (sizeStore.headerHeight.get() + this.el.parentNode.offsetTop)
    const availableHeight = resize.height() - top
    const alignBottom = this.el.offsetHeight > availableHeight

    if (this.alignBottom === alignBottom) return
    this.alignBottom = alignBottom
    this.el.parentNode.classList.toggle('alignBottom', alignBottom)
  }

  resize () {
    super.resize()
    this.computeAlignement()
  }

  flush () {
    if (this.resizeObserver) this.resizeObserver.disconnect()
    this.resizeObserver = null

    super.flush()
  }
}

export default ProductDetails
