
import MainPage from 'navigation/pages/MainPage'

import HomeCarousel from './partials/HomeCarousel'

class Home extends MainPage { 
 static pageName = 'Home'; 
  getModuleMap () {
    return {
      ...super.getModuleMap(),
      homeCarousel: ['.home__carousel', HomeCarousel]
    }
  }
}

export default Home
