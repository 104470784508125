
import anime from 'animejs'
import { last } from 'lodash'

import Carousel from 'components/carousel/Carousel'
import detect from 'helpers/detect'
import resize from 'helpers/resize'
import Component from 'navigation/component/Component'
import CollectionPopin from 'sections/lookbooks/partials/CollectionPopin'

class ProductZoom extends Component {
  getModuleMap () {
    return {
      carousel: ['.product-zoom__inner', Carousel]
    }
  }

  constructor () {
    super(...arguments)
    this.mouseState = null
    this.bindRefs()
    this.bindModules()
  }

  bindModules () {
    super.bindModules()
    this.savedOrder = this.modules.carousel.items.slice(0)
  }

  open (id = 0) {
    this.resetState(true)
    this.savedOrder.forEach(item => {
      this.modules.carousel.el.appendChild(item)
    })
    this.modules.carousel.items = Array.from(this.modules.carousel.el.children)

    this.refs.imageZoom.forEach(i => {
      i.src = i.getAttribute('data-src')
    })

    return CollectionPopin.prototype.open.call(this, id)
  }

  close () {
    return CollectionPopin.prototype.close.call(this)
  }

  onClose = () => this.close()

  bindEvents (add = true) {
    super.bindEvents()
    const method = add ? 'on' : 'off'
    const methodNative = add ? 'addEventListener' : 'removeEventListener'

    // this.modules.carousel[method]('update', this.onUpdate)
    this.modules.carousel[method]('complete', this.onRearrangeCarousel)
    this.refs.closeZoom[methodNative]('click', this.onClose)

    if (!detect.touch) {
      this.el[methodNative]('mousemove', this.onMouseMove)
      this.modules.carousel[method]('click', this.onClick)
    }
  }

  onMouseMove = ({ clientX }) => {
    if (this.modules.carousel.animating || !this.opened) return

    const position = clientX / resize.width()
    const state = position < 0.2 ? 'prev' : position > .8 ? 'next' : 'close'
    this.updateState(state)
  }

  onClick = (event) => {
    if (this.modules.carousel.animating || !this.mouseState) return
    event && event.preventDefault()
    if (this.mouseState === 'close') this.close()
    else this.modules.carousel[this.mouseState]()
    // this.resetState()
    this.animateOffset(0)
  }

  updateState (state) {
    if (detect.touch || state === this.mouseState) return

    this.el.classList.remove(this.mouseState)
    this.el.classList.add(state)
    this.mouseState = state

    if (state !== 'close') this.bait(state)
    else this.animateOffset(0)
  }

  resetState (direct = false) {
    if (direct) this.modules.carousel.shift = 0
    else this.animateOffset(0)
    this.el.classList.remove(this.mouseState)
    this.mouseState = null
  }

  bait (state) {
    const offset = (state === 'prev' ? 1 : -1) * resize.width() * .15
    this.animateOffset(offset)
  }

  animateOffset (shift) {
    const { carousel } = this.modules
    if (carousel.shift === shift) return
    anime.remove(carousel)
    return anime({
      targets: carousel,
      shift,
      easing: 'easeOutCubic',
      duration: 400,
      complete: () => carousel.updatePosition(true),
      change: () => carousel.updatePosition(true)
    })
  }

  onRearrangeCarousel = (step) => {
    const { carousel } = this.modules
    const lastIndex = carousel.items.length - 1

    if (step <= 0) {
      const firstItem = carousel.items[0]
      const lastItem = last(carousel.items)
      carousel.el.insertBefore(lastItem, firstItem)
      carousel.items = Array.from(carousel.el.children)
      carousel.resize()
      this.modules.carousel.goTo(1, true)
    } else if (step >= lastIndex) {
      const firstItem = carousel.items[0]
      carousel.el.appendChild(firstItem)
      carousel.items = Array.from(carousel.el.children)
      carousel.resize()
      this.modules.carousel.goTo(lastIndex - 1, true)
    }
  }

  onUpdate = (step) => {
    this.onRearrangeCarousel(step)
  }
}

export default ProductZoom
