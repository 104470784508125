import { isArray, each, map, invokeMap, defer } from 'lodash'
import Emitter from 'tiny-emitter'

import router from 'core/router'
import store from 'store/store'

class Component extends Emitter {
  constructor (el, options) {
    super()
    this.el = el
    this.options = options
    defer(() => this.deferredInit())
  }

  deferredInit () {
    this.bindEvents(true)
  }

  /* BINDINGS EVENTS */

  bindEvents (add = true) {}

  /* BINDINGS REFS */

  bindRefs (target) {
    if (!target) target = this.el

    const parseRefs = (selector, forceArray, refs) => Array.from(target.querySelectorAll(`*[${selector}]`)).reduce((memo, el) => {
      const keys = el.getAttribute(selector)
      if (keys) {
        keys.split(/[ ,]+/).forEach(key => {
          if (memo[key] || forceArray) {
            if (!memo[key]) memo[key] = []
            else if (!isArray(memo[key])) memo[key] = [memo[key]]
            memo[key].push(el)
          } else { memo[key] = el }
        })
      }
      return memo
    }, refs)

    this.refs = parseRefs('data-ref', false, {})
    this.refs = parseRefs('data-refs', true, this.refs)
  }

  /* BINDINGS MODULES */

  bindModules () {
    if (this.modules) return

    this.modulesArray = []
    this.modules = {}

    each(this.getModuleMap(), ([selector, Module], key) => {
      const array = map(
        selector === 'self' ? [this.el] : this.el.querySelectorAll(selector),
        el => {
          if (el.binded && ~el.binded.indexOf(key)) return
          const m = new Module(el, this.getModuleParams(el, Module))
          if (!el.binded) el.binded = []
          el.binded.push(key)
          return m
        }
      )
      if (array) this.modules[key] = array.length === 0 ? null : array.length === 1 ? array[0] : array
      this.modulesArray.push(...array)
    })
  }

  getModuleMap () {
    return {}
  }

  getModuleParams () {
    return { parent: this }
  }

  /* BINDINGS ROUTERS */

  bindInternalRouter () {
    const types = ['panel', 'menu', 'search', 'popin']
    this.routerLinks = []

    types.forEach(type => {
      const links = [].slice.call(this.el.querySelectorAll(`[data-${type}`))
      links.forEach(link => {
        if (link.hasListenerAttached) return
        link.addEventListener('click', this.onLinkClicked)
        link.hasListenerAttached = true
        link.store = store[type]
        link.storeName = type
      })

      this.routerLinks.push(...links)
    })
  }

  unbindInternalRouter () {
    this.routerLinks && this.routerLinks.forEach(link => link.removeEventListener('click', this.onLinkClicked))
  }

  onLinkClicked = (event) => {
    const link = event.currentTarget
    if ((event.ctrlKey || event.metaKey) && link.tagName.toLowerCase() === 'a') return false
    const path = (link.getAttribute('data-' + link.storeName) || link.getAttribute('href')).replace(router.route, '')
    link.store.set(path.replace(router.route, ''))
    event.preventDefault()
  }

  /* RESIZE */

  resize () {
    invokeMap(this.modulesArray, 'resize')
  }

  /* FLUSH */

  flush () {
    this._flushed = true
    this.bindEvents(false)
    this.unbindInternalRouter()
    invokeMap(this.modulesArray, 'flush')
  }
}

export default Component
