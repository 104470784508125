import { createStore } from 'helpers/state'

const store = createStore({

  lang: 'fr',
  path: null,
  loading: 1,
  message: null,

  // CHECKOUT
  checkoutStep: null,
  checkoutPickup: null,

  // ROUTERS
  routers: {},
  menu: null,
  panel: null,
  search: null,
  popin: null,

  // HEADERS
  cart: null,
  logged: null,
  alternateLinks: null
})

export default store
