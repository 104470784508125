import browser from 'helpers/browser'
import Component from 'navigation/component/Component'

const observerCallback = (entries) => {
  entries.forEach((entry) => {
    if (entry.target.onIntersect) entry.target.onIntersect(entry)
  })
}

const observer = new IntersectionObserver(observerCallback, {
  rootMargin: '1000% 0px -5% 0px',
  threshold: 0
})

class Reveal extends Component {
  constructor () {
    super(...arguments)
    this.delay = (+this.el.getAttribute('data-delay')) || 0
  }

  getObserver () {
    return observer
  }

  show () {
    setTimeout(this.observe, 700)
  }

  observe = () => {
    this.el.onIntersect = this.onObserverUpdate
    this.getObserver().observe(this.el)
  }

  onObserverUpdate = ({ target, isIntersecting }) => {
    target.style.setProperty('--stagger-delay', this.delay + 's')
    browser.waitRepaint(() => {
      target.classList.toggle('reveal-show', isIntersecting)
    })
  }

  flush () {
    this.el.onIntersect = null
    this.getObserver().unobserve(this.el)
    super.flush()
  }
}

export default Reveal
