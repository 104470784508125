
import browser from 'helpers/browser'
import Component from 'navigation/component/Component'

class RevealList extends Component {
  constructor (el) {
    super(...arguments)
    this.prepareChildren()
  }

  prepareChildren () {
    this.incDelay = 0
    this.stagger = (+this.el.getAttribute('data-delay')) || 0.12
    this.children = this.getChildren()
    this.observer = new IntersectionObserver(this.onObserverUpdate, {
      rootMargin: '1000% 0px -5% 0px',
      threshold: 0
    })
  }

  reset () {
    if (this.children && this.observer) {
      this.children.forEach(child => {
        this.observer.unobserve(child)
      })
    }

    this.children = this.getChildren()
    this.observe()
  }

  getChildren () {
    return Array.from(this.el.children)
  }

  observe = () => {
    this.children.forEach(child => this.observer.observe(child))
  }

  show () {
    setTimeout(this.observe, 600)
  }

  hide () {
    this.observer.disconnect()
  }

  // resetDelay = debounce(() => {
  //   this.incDelay = 0
  // }, 50)

  onObserverUpdate = (entries) => {
    let previousTop = 0

    entries.forEach(({ target, isIntersecting }, index) => {
      if (isIntersecting) {
        const top = target.topLine
        if (previousTop !== top) this.incDelay = 0

        const delay = this.stagger * Math.min(this.incDelay, 10)
        target.style.setProperty('--stagger-delay', delay + 's')

        this.incDelay++
        previousTop = top
      }

      browser.waitRepaint(() => {
        target.classList.toggle('reveal-show', isIntersecting)
      })
    })
  }

  resize () {
    super.resize()
    this.offsetTops = []
    this.children.forEach((child, index) => {
      child.topLine = child.offsetTop
    })
  }

  flush () {
    this.observer.disconnect()
    this.children = null
    this.observer = null
    super.flush()
  }
}

export default RevealList
