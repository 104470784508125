import Component from 'navigation/component/Component'
import DrawerPanel from 'components/drawer-panel/DrawerPanel'
import router from 'core/router'
import store from 'store/store'
import browser from 'helpers/browser'

class CategoriesList extends Component {
  getModuleMap = () => ({
    ...super.getModuleMap(),
    drawer: ['.drawer-panel', DrawerPanel]
  })

  constructor (el) {
    super(...arguments)
    this.bindRefs()
    this.bindModules()
  }

  bindRefs () {
    super.bindRefs(...arguments)
    this.default = this.el.querySelector('[data-slug][data-default]')
  }

  bindEvents () {
    this.refs.buttons.forEach(button => button.addEventListener('click', this.onButtonClick))
    store.path.listenAndStart(this.onRouteUpdate)
  }

  onRouteUpdate = (path) => {
    path = path.split('?')[0]
    let found = null
    this.refs.links.forEach((item) => {
      const link = item.getAttribute('href').replace(router.root, '')
      item.classList.toggle('current', link === path)

      if (link === path) {
        const parent = browser.findParent(item, '*[data-slug]')
        this.onButtonClick({ currentTarget: parent })
        found = true
      }
    })

    if (!found) this.reset()
  }

  reset (openCollection) {
    this.refs.items.forEach((item, key) => {
      if (this.default) item.classList.toggle('opened', item === this.default)
      else item.classList.toggle('opened', false)
    })
  }

  onButtonClick = (event) => {
    const button = event.currentTarget
    const buttonSlug = button.getAttribute('data-slug')

    this.refs.items.forEach((item) => {
      item.classList.toggle('opened', buttonSlug === item.getAttribute('data-slug'))
    })
  }
}

export default CategoriesList
