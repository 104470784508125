import Reveal from 'components/reveal/Reveal'
import text from 'helpers/text'

const observerCallback = (entries) => {
  entries.forEach((entry) => {
    if (entry.target.onIntersect) entry.target.onIntersect(entry)
  })
}

const observer = new IntersectionObserver(observerCallback, {
  rootMargin: '1000% 0px -20% 0px',
  threshold: 0.5
})

class MaskReveal extends Reveal {
  constructor () {
    super(...arguments)
    this.buildSpan()
  }

  getObserver () {
    return observer
  }

  buildSpan () {
    // this.span = document.createElement('span')
    this.el.innerHTML = text.spanify(this.el.innerHTML, false, false)
    // this.span.classList.add('mask-reveal__inner')
    // this.el.innerHTML = ''
    // this.el.appendChild(this.span)
  }
}

export default MaskReveal
