
import { defer } from 'lodash'

import store from 'store'
import sizeStore from 'store/sizeStore'

import CustomPageManager from './CustomPageManager'

class SuperPageManager extends CustomPageManager {
  resetScroll () {
    const minHeight = sizeStore.notificationHeight.get()
    if (document.scrollingElement.scrollTop > minHeight) document.scrollingElement.scrollTop = minHeight
  }

  removePage () {
    this.resetScroll()
    super.removePage()
  }

  initializePage () {
    this.extractAlternateLink(document)
    return super.initializePage(...arguments)
  }

  updatePageInfo (page) {
    this.extractAlternateLink(page)
    return super.updatePageInfo(...arguments)
  }

  onRouteUpdate () {
    const updated = super.onRouteUpdate(...arguments)
    if (updated !== true) return
    this.addLoading()
    store.loading.set(0)
    defer(() => {
      store.loading.set(0.1)
    })
  }

  extractAlternateLink (el) {
    const alternates = Array.from(el.querySelectorAll('link[rel="alternate"]'))
    if (alternates) {
      store.alternateLinks.set(alternates.reduce((memo, a) => {
        memo[a.getAttribute('hreflang')] = a.getAttribute('href')
        return memo
      }, {}))
    }
  }

  addLoading () {
    document.documentElement.classList.add('loading')
  }

  removeLoading () {
    document.documentElement.classList.remove('loading')
  }

  cancelTransition () {
    super.cancelTransition()
    this.removeLoading()
  }

  triggerInternalRouting () {
    super.triggerInternalRouting(...arguments)
    this.removeLoading()
  }

  preparePage (el) {
    super.preparePage(...arguments)
      .then(() => {
        this.removeLoading()
      })
  }

  createPage (el) {
    return super.createPage(...arguments)
  }
}

export default SuperPageManager
