
import detect from 'helpers/detect'
import store from 'store'

import PageManager from './PageManager'

const decode = (str) => {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

const extractInfoFromXhr = (xhr) => {
  const auth = xhr.getResponseHeader('X-Fursac-Auth')
  const cart = xhr.getResponseHeader('X-Fursac-Cart')
  let error = xhr.getResponseHeader('X-Fursac-Error')
  let message = xhr.getResponseHeader('X-Fursac-Message')

  if (error) error = decode(error)
  if (message) message = decode(message)
  if (auth) store.logged.set(!!parseInt(auth))
  if (cart) store.cart.set(parseInt(cart))

  const page = xhr.response
  const obj = extractInfo(page, { error, message })
  if (obj.message) message = obj.message
  if (obj.error) error = obj.error

  return {
    auth, cart, error, message
  }
}

function extractInfo (page, { error = null, message = null } = {}) {
  if (!error || !message) {
    if (page) {
      const errorMessage = page.querySelector('.error-message')
      const successMessage = page.querySelector('.success-message')

      if (errorMessage) {
        error = errorMessage.innerHTML
        errorMessage.parentNode.removeChild(errorMessage)
      } else if (successMessage) {
        message = successMessage.innerHTML
        successMessage.parentNode.removeChild(successMessage)
      }
    }
  }

  if (error || message) {
    store.message.set({
      error: !!error,
      text: error || message
    })
  }

  return {
    error, message
  }
}

class CustomPageManager extends PageManager {
  initializePage () {
    super.initializePage(...arguments)
    extractInfo(this.page.current.el)
  }

  pageLoaded (pathName, xhr, requestOptions) {
    extractInfoFromXhr(xhr)
    super.pageLoaded(...arguments)
  }

  extractPageFromXHR (el) {
    if (detect.safari || detect.firefox) {
      if (!this.tmpDiv) this.tmpDiv = document.createElement('div')
      this.tmpDiv.innerHTML = '' + el.innerHTML
      return super.extractPageFromXHR(this.tmpDiv)
    }
    return super.extractPageFromXHR(...arguments)
  }
}

export { extractInfoFromXhr, extractInfo }

export default CustomPageManager
