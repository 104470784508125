import { defer } from 'lodash-es'
import anime from 'animejs'

import scroll from 'core/scroll'

import Page from './Page'

export default class PanelPage extends Page { 
 static pageName = 'PanelPage'; 
  constructor (el, options = {}) {
    super(...arguments)
    const footer = el.querySelector('.footer')
    if (footer) footer.parentNode.removeChild(footer)
    el.classList.add(options.className || 'panel__page')
  }

  prepare (previousPage) {
    this.el.classList.add('appearing')

    return super.prepare(previousPage)
      .then(() => this.resetScroll())
  }

  resetScroll () {
    if (this.pageManager.container.scrollTop) return scroll.scrollTo(0, { target: this.pageManager.container, duration: 200 })
  }

  /* SHOW */

  show (previousPage) {
    this.resize()
    Page.prototype.show.call(this, previousPage)
    const isSamePage = previousPage === this.pageName() && previousPage !== PanelPage.pageName

    return anime(Object.assign((isSamePage
      ? {
          opacity: [0, 1],
          easing: 'easeOutCubic'
        }
      : {
          opacity: [0, 1],
          translateX: previousPage ? ['20%', 0] : null,
          easing: 'easeOutCubic'
        }),
    {
      targets: this.el,
      duration: 400,
      delay: previousPage ? 50 : 0,
      changeBegin: () => {
        this.invokeShow()
      },
      complete: () => {
        defer(() => this.resize())
      }
    })).finished
  }

  transitionComplete () {
    this.el.classList.remove('appearing')
    super.transitionComplete()
  }

  /* HIDE */

  hide (nextPage) {
    // this.el.classList.add('disappearing')
    const isSamePage = nextPage === this.pageName() && nextPage !== PanelPage.pageName

    return anime(Object.assign((isSamePage
      ? {
          // opacity: [1, 0],
          // easing: 'easeInCubic'
        }
      : {
          opacity: [1, 0],
          translateX: nextPage ? [0, '-20%'] : null,
          easing: 'easeOutCubic'
        }),
    {
      targets: this.el,
      delay: nextPage ? 50 : 0,
      duration: 400
    })).finished
  }
}
