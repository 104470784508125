
import anime from 'animejs'

import Carousel from 'components/carousel/Carousel'
import router from 'core/router'
import scroll from 'core/scroll'
import browser from 'helpers/browser'
import detect from 'helpers/detect'
import Component from 'navigation/component/Component'

class HomeCarousel extends Component {
  constructor (el) {
    super(...arguments)
    this.bindRefs()
    this.current = 0
    this.autoplay = this.el.getAttribute('data-autoplay') !== 'false'
  }

  bindRefs () {
    super.bindRefs(...arguments)
    this.total = this.refs.carouselItems.length

    if (this.total)
      this.updateSrc(this.refs.carouselItems[0], false)

    if (this.total === 1)
      this.refs.carouselItems[0].classList.add('clickable')
  }

  bindEvents (add = true) {
    if (this.total <= 1) return

    const method = add ? 'addEventListener' : 'removeEventListener'
    this.refs.carouselPrev[method]('click', this.prev)
    this.refs.carouselNext[method]('click', this.next)
    this.el[method](!detect.touch ? 'mousedown' : 'touchstart', this.mousedown)
    window[method](!detect.touch ? 'mouseup' : 'touchend', this.mouseup)
    window[method](!detect.touch ? 'mousemove' : 'touchmove', this.mousemove, { passive: false })
    window[method]('keydown', this.onKeyDown)

    this.refs.carouselItems.forEach(item => item[method]('click', this.preventDefault))

    if (this.autoplay) {
      if (add) this.interval = setInterval(this.tick, 5000)
      else clearInterval(this.interval)
    }
  }

  preventDefault = event => {
    event.stopImmediatePropagation()
    event.stopPropagation()
    event.preventDefault()
  }

  tick = () => {
    this.next()
  }

  getFrame (event) {
    return {
      time: Date.now(),
      y: (event.pageY - scroll.scrollTop()),
      x: event.pageX
    }
  }

  hasScrolled () {
    return Carousel.prototype.hasScrolled.call(this, ...arguments)
  }

  hasClicked () {
    return Carousel.prototype.hasClicked.call(this, ...arguments)
  }

  updateSrc (item, clear) {
    const child = item.querySelector('[data-src]')
    if (!child) return
    const dataSrc = child.getAttribute('data-src')
    if (dataSrc) child.src = clear ? null : dataSrc
  }

  mousedown = (event) => {
    clearInterval(this.interval)
    event = browser.mouseEvent(event)
    const frame = this.getFrame(event)
    this.scrollLocked = false
    this.frames = { first: frame, history: [frame] }
  }

  mousemove = (event) => {
    if (!this.frames) return

    const mouseEvent = browser.mouseEvent(event)
    const frame = this.getFrame(mouseEvent)
    const { first } = this.frames

    if (this.scrollLocked || this.hasScrolled(frame, first)) event.preventDefault()
    else return

    event && event.stopImmediatePropagation()

    this.frames.history.push(frame)
    while (this.frames.history.length > 5) this.frames.history.shift()
  }

  mouseup = (event) => {
    if (!this.frames) return

    event = browser.mouseEvent(event)

    const frame = this.getFrame(event)
    const { first, history } = this.frames
    const last = history[0]

    if (this.hasClicked(frame, first)) this.click(event, event.target)

    if (!this.frames) return // recheck here

    const speed = (frame.x - last.x) / (frame.time - last.time)

    if (Math.abs(speed) > 0.05) {
      if (speed > 0) this.prev()
      else this.next()
    }

    this.frames = null
  }

  click (event, target) {
    if (target.tagName.toLowerCase() === 'button') return
    const link = this.refs.carouselLabels[this.current]
    if (!link) return this.emit('click', event)
    const href = router.getLinkPath(link.firstElementChild)
    router.navigate(href)
  }

  next = (event) => {
    clearInterval(this.interval)
    let next = this.current + 1
    if (next >= this.total) next = 0
    this.animate(next, true)
  }

  prev = () => {
    clearInterval(this.interval)
    let previous = this.current - 1
    if (previous < 0) previous = this.total - 1
    this.animate(previous, false)
  }

  getLabelChildren (label) {
    if (label.firstElementChild.tagName.toUpperCase() === 'A') return label.firstElementChild.children
    return label.children
  }

  animate (next, forward) {
    const previous = this.current
    const currentItem = this.refs.carouselItems[previous]
    const nextItem = this.refs.carouselItems[next]

    const currentLabel = this.refs.carouselLabels[previous]
    const nextLabel = this.refs.carouselLabels[next]

    anime.remove([currentItem, nextItem, currentLabel, nextLabel])

    nextItem.style.display = 'block'
    nextLabel.style.display = 'flex'

    this.updateSrc(nextItem, false)

    const tl = anime.timeline({
      easing: 'easeOutCubic',
      duration: 500
    })

    const opacityIn = {
      value: [0, 1]
      // duration: 200,
      // delay: 100,
      // easing: 'linear'
    }
    const opacityOut = {
      value: 0
      // duration: 200,
      // easing: 'linear'
    }

    tl.add({
      targets: currentItem,
      translateX: forward ? '-8%' : '8%',
      opacity: opacityOut.value,
      complete: () => {
        currentItem.style.display = 'none'
        this.updateSrc(currentItem, true)
      }
    }, 0)

    tl.add({
      targets: this.getLabelChildren(currentLabel),
      translateY: [0, 10],
      opacity: opacityOut.value,
      // delay: (el, i) => i * 75,
      complete: () => {
        currentLabel.style.display = 'none'
      }
    }, 0)

    tl.add({
      targets: nextItem,
      translateX: [forward ? '8%' : '-8%', '0%'],
      opacity: opacityIn.value,
      delay: 200
    }, 0)

    tl.add({
      targets: this.getLabelChildren(nextLabel),
      translateY: [-10, 0],
      opacity: opacityIn.value,
      delay: (el, i) => i * 150 + 200
    }, 0)

    this.current = next

    return tl.finished
  }

  flush () {
    super.flush()
  }
}

export default HomeCarousel
