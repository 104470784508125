import Navigo from 'navigo'

import Config from 'core/config'
import store from 'store/store'

// const buildRoot = (lang) => Config.baseurl
const buildRoot = (lang) => Config.baseurl + '/' + lang

const router = new Navigo(buildRoot(Config.lang), false, false)
router.path = () => window.location.href
router.uri = () => window.location.pathname

if ('scrollRestoration' in window.history)
  window.history.scrollRestoration = 'manual'

window.updateLang = (lang) => {
  if (lang === Config.lang) return
  Config.lang = lang
  router.root = buildRoot(Config.lang)
  router.lastRouteResolved().url = ''
}

const history = []

router.hooks({
  before: (resolve) => {
    const [path, hash] = router.path().split('#')
    const [currentPath, currentHash] = (router.currentPage() || '').split('#')
    const isSamePage = currentPath === path && hash !== currentHash

    history.push(router.path())

    if (isSamePage) return resolve(false)

    if (history.length > 1) {
      store.search.set(null)
      store.panel.set(null)
      store.menu.set(null)
    }

    store.path.set(router.path().replace(router.root, ''))

    resolve()
  },
  after: () => {
    // history.push(router.path())
  }
})

router.history = () => history
router.lastPage = () => history.length > 1 ? history[history.length - 2] : false
router.currentPage = () => history.length > 0 ? history[history.length - 1] : false

router._findLinks = () => [].slice.call(document.querySelectorAll('[data-navigo], .data-navigo'))
router.getLinkPath = (link) => {
  return link.getAttribute('href').replace(router.root, '')
}

export default router
