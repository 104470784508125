import Category from 'sections/category/Category'
import Cart from 'sections/checkout/Cart'
import Checkout from 'sections/checkout/Checkout'
import CheckoutPayment from 'sections/checkout/CheckoutPayment'
import CheckoutPersonalDetails from 'sections/checkout/CheckoutPersonalDetails'
import CheckoutSummary from 'sections/checkout/partials/CheckoutSummary'
import Customer from 'sections/customer/Customer'
import Profile from 'sections/customer/Profile'
import Collection from 'sections/lookbooks/Collection'
import Product from 'sections/product/Product'
import Search from 'sections/search/Search'
import Stores from 'sections/stores/Stores'
import Menu from 'sections/home/Menu'
import CheckoutShipping from 'sections/checkout/CheckoutShipping'
import CheckoutPickup from 'sections/checkout/partials/CheckoutPickup'
import Home from 'sections/home/Home'
import Store from 'sections/stores/Store'

const pageMap = {
  /* Main */
  Category,
  Checkout,
  Product,
  Collection,
  Stores,
  Store,
  Home,

  /* Panel */
  Cart,
  Menu,

  /* Search */
  Search,

  /* Checkout */
  CheckoutPersonalDetails,
  CheckoutShipping,
  CheckoutSummary,
  CheckoutPayment,
  CheckoutPickup,

  /* Customer */
  Customer,
  Profile
}

export default pageMap
