
import { throttle } from 'lodash'

import scroll from 'core/scroll'
import resize from 'helpers/resize'
import InternalPage from 'navigation/pages/InternalPage'
import router from 'core/router'
import browser from 'helpers/browser'
import RevealList from 'components/reveal-list/RevealList'
import MainPage from 'navigation/pages/MainPage'
import MaskReveal from 'components/maskReveal/MaskReveal'

class CategoryProducts extends InternalPage { 
 static pageName = 'CategoryProducts'; 
  constructor () {
    super(...arguments)
    this.bindRefs()
    this.parsePages()
  }

  getModuleMap () {
    return {
      revealList: ['.category__list', RevealList],
      maskReveal: ['.mask-reveal', MaskReveal]
    }
  }

  getTranslateY (hide) {
    return MainPage.prototype.getTranslateY.call(this, hide)
  }

  bindEvents (add = true) {
    scroll[add ? 'on' : 'off'](this.onScroll)
  }

   onScroll = throttle(() => {
     if (this.bottomList < scroll.scrollTop()) this.loadNewPage()
   }, 1000)

   loadNewPage () {
     if (this.loading || this.currentPage === this.nbPages) return
     this.loading = true

     this.currentPage++
     const link = this.endpoint + (~this.endpoint.indexOf('?') ? '&' : '?') + 'page=' + this.currentPage

     const xhr = new XMLHttpRequest()
     xhr.withCredentials = true
     xhr.responseType = 'document'
     xhr.open('GET', link, true)
     xhr.onload = this.onPageLoaded
     xhr.send()
   }

   onPageLoaded = ({ currentTarget: xhr }) => {
     this.loading = false
     const page = xhr.response

     const items = Array.from(page.querySelectorAll('.category__list .category__text, .category__list .category__product'))
     items.forEach(item => this.refs.categoryList.appendChild(item))
     this.modules.revealList.reset()
     router.updatePageLinks()
     browser.waitRepaint(() => this.resize())
   }

   parsePages () {
     this.currentPage = +this.refs.categoryList.getAttribute('data-page')
     this.nbPages = +this.refs.categoryList.getAttribute('data-nb-pages')
     this.endpoint = this.refs.categoryList.getAttribute('data-src')
   }

   resize () {
     super.resize()
     const offset = resize.height() * 3
     this.bottomList = this.refs.categoryList.getBoundingClientRect().bottom + scroll.scrollTop() - resize.height() - offset
   }
}

export default CategoryProducts
