import { each } from 'lodash-es'

import AddressField from 'components/address-field/AddressField'
import Component from 'navigation/component/Component'

class AddressForm extends Component {
  constructor (el, refs) {
    super(el)
    this.bindModules()
    this.parseForm()
  }

  getModuleMap () {
    return {
      addressInput: ['input[autocomplete="street-address"]', AddressField]
    }
  }

  parseForm () {
    this.stateInput = this.el.querySelector('select[autocomplete="address-level1"]')
    this.statesByCountry = JSON.parse(this.stateInput.getAttribute('data-states'))
    this.countryInput = this.el.querySelector('select[autocomplete="country"]')

    this.onCountryUpdate()
  }

  onCountryUpdate = () => {
    if (!this.stateInput) return
    const country = this.countryInput.value
    const states = this.statesByCountry[country]
    this.el.classList.toggle('with-states', !!states)
    this.el.classList.toggle('without-zipcode', country === 'HK')
    this.stateInput.innerHTML = ''
    if (!states) return

    each(states, (value, key) => {
      const option = document.createElement('option')
      option.value = key
      option.textContent = value
      this.stateInput.appendChild(option)
    })
  }

  bindEvents (add = true) {
    const method = add ? 'addEventListener' : 'removeEventListener'
    this.countryInput[method]('change', this.onCountryUpdate)
    this.modules.addressInput[add ? 'on' : 'off']('select', this.onAddressSelected)
  }

  onAddressSelected = (address) => {
    this.fillAddress(address)
  }

  fillAddress = (address) => {
    each(address, (value, key) => {
      const input = this.el.querySelector(`[name="${key}"]`)
      if (!input) return
      input.value = value
      if (key === 'country') this.onCountryUpdate()
    })
  }

  flush () {
    super.flush()
  }
}

export default AddressForm
