import { defer, invokeMap } from 'lodash-es'

import store from 'store'
import PageManager from 'navigation/page-manager/MainPageManager'
import MainPage from 'navigation/pages/MainPage'
import Component from 'navigation/component/Component'
import resize from 'helpers/resize'
import router from 'core/router'
import style from 'core/style'
import Panel from 'components/panel/Panel'
import Header from 'components/header/Header'
import QuickAccess from 'components/quick-access/QuickAccess'
import Notification from 'components/notification/Notification'
import scroll from 'core/scroll'
import mqStore from 'store/mqStore'
import sizeStore from 'store/sizeStore'
import MenuPanel from 'components/menu-panel/MenuPanel'
import math from 'helpers/math'
import SearchPanel from 'components/search-panel/SearchPanel'
import LoadingBar from 'components/loading-bar/LoadingBar'
import Popin from 'components/popin/Popin'
import Toaster from 'components/toaster/Toaster'

class App extends Component {
  getModuleMap = () => ({
    panel: ['.panel', Panel],
    menu: ['.menu-panel', MenuPanel],
    search: ['.search-panel', SearchPanel],
    popin: ['.popin', Popin],
    notification: ['.notification', Notification],
    quickAccess: ['.quick-access', QuickAccess],
    header: ['.header', Header],
    loadingBar: ['.loading-bar', LoadingBar],
    toaster: ['.toaster', Toaster]
  })

  constructor (el) {
    super(...arguments)
    scroll.init()

    this.bindRefs()
    this.bindModules()
    this.createPageManager()

    resize.setRoot(this.refs.container)
    router.resolve()
  }

  createPageManager () {
    if (!this.refs.container) return
    const pageManager = new PageManager(this.refs.container, '.page', MainPage)
    pageManager.once('show', this.show)
    store.routers.get().main = pageManager
    this.modules.pageManager = pageManager
    this.modulesArray.push(pageManager)
  }

  show = () => {
    invokeMap(this.modulesArray, 'show')
  }

  bindEvents () {
    resize.add(this)

    scroll.on(this.scroll)
    sizeStore.headerHeight.listen(this.updateFixedTop)
    sizeStore.notificationHeight.listen(this.updateFixedTop)

    this.resize()
    this.scroll()
  }

  // SCROLL
  scroll = () => {
    this.updateFixedTop()
  }

  updateFixedTop = () => {
    const fixedTop = Math.max(0, sizeStore.notificationHeight.get() - scroll.scrollTop())
    const pageTop = fixedTop + sizeStore.headerHeight.get()
    if (pageTop === this.pageTop) return
    this.pageTop = pageTop
    document.documentElement.style.setProperty('--notification-height', sizeStore.notificationHeight.get() + 'px')
    document.documentElement.style.setProperty('--header-top', fixedTop + 'px')
    document.documentElement.style.setProperty('--page-top', (fixedTop + sizeStore.headerHeight.get()) + 'px')
  }

  // RESIZE
  resize = () => {
    this.updateSizes()
    defer(() => super.resize())
  };

  updateSizes () {
    const height = resize.height()
    const width = resize.width()
    const scale = width / (mqStore.tabletPortrait.get() ? style.widthReferenceM : style.widthReference)
    const clampScale = math.clamp(scale, .9, 1.1)

    document.documentElement.style.setProperty('--vh', height + 'px')
    document.documentElement.style.setProperty('--vw', width + 'px')
    document.documentElement.style.setProperty('--rem', scale + 'px')
    document.documentElement.style.setProperty('--rem-clamp', clampScale + 'px')

    if (this.refs) {
      const { offsetTop } = this.refs.container
      document.documentElement.style.setProperty('--available-height', (height - offsetTop) + 'px')
      document.documentElement.style.setProperty('--available-inner-height', (resize.dimension().innerHeight - offsetTop) + 'px')
    }
  }
}

export default App
